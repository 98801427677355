<template lang="pug">
.main-wrapper.agenda-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Agendas</b>

		Dialog(header='Remover agenda' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover essa agenda do especialista <b>{{ dialogApagar_data.nm_especialista }}</b>?
			.ta-center.my-4
				Button.p-button-danger(label='Remover' @click='remove(dialogApagar_data.id)')
		
		
		TabView(@tab-change='onTabChange')
			TabPanel(header='Consultas' v-bind:ieTipoAgenda="'C'" :active="filters.ie_tipo_agenda === 'C'" :disabled='waiting.list')
			TabPanel(header='Exames' v-bind:ieTipoAgenda="'E'" :active="filters.ie_tipo_agenda === 'E'" :disabled='waiting.list')
			TabPanel(header='Laboratório' v-bind:ieTipoAgenda="'L'" :active="filters.ie_tipo_agenda === 'L'" :disabled='waiting.list')
			TabPanel(header='Procedimentos' v-bind:ieTipoAgenda="'P'" :active="filters.ie_tipo_agenda === 'P'" :disabled='waiting.list')

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid
				.p-col-12.p-md-4(v-if="filters.ie_tipo_agenda === 'C'")
					label.form-label Especialidade:
					ProgressBar(v-if="waiting.especialidades" mode="indeterminate")
					Dropdown(
						v-else
						:options='options.especialidades'
						placeholder='TODOS'
						optionLabel='text'
						optionValue='value'
						dataKey='value'
						:filter='true'
						v-model='filters.cd_especialidade'
						@change='applyFilters()'
					)
				.p-col-12.p-md-4(:class="{ 'p-md-3': filters.ie_tipo_agenda === 'C' }")
					label.form-label Procedimento:
					ProgressBar(v-if="waiting.procedimentos" mode="indeterminate")
					CustomDropdown(
						v-else
						:options='options.procedimentos[filters.ie_tipo_agenda]'
						placeholder='TODOS'
						optionLabel='text'
						optionValue='value'
						dataKey='value'
						:filter='true'
						v-model='filters.cd_procedimento_estabelecimento'
						@change='applyFilters()'
					)
				.p-col-12.p-md-4(v-if="filters.ie_tipo_agenda === 'C'")
					label.form-label Nome do especialista:
					.p-inputgroup
						InputText(placeholder='Nome'
							@keyup.enter.native='applyFilters()'
							@input='checkEmpty_nm_especialista()'
							v-model='filters.nm_especialista'
							v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-4
					label.form-label Situação:
					Dropdown(
						:options='options.ie_status'
						placeholder='TODAS'
						optionLabel='label'
						optionValue='value'
						dataKey='value'
						v-model='filters.ie_status'
						@change='applyFilters()'
					)
				.p-col-12.p-md-4(v-if="filters.ie_tipo_agenda === 'E' || filters.ie_tipo_agenda === 'L' || filters.ie_tipo_agenda === 'P'")
					label.form-label Grupo de Procedimentos:
					ProgressBar(v-if="waiting.grupos" mode="indeterminate")
					Dropdown(
						v-else
						:options='options.grupos_procedimentos'
						placeholder='TODAS'
						optionLabel='text'
						optionValue='value'
						dataKey='value'
						v-model='filters.cd_grupo_procedimento'
						@change='applyFilters()'
					)

		.ta-right.my-2(v-if='!waiting.list')
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/agenda/salvar/0/')")


		ProgressBar(v-if='waiting.list' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

		div(v-else)

			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em")
						Panel.panel-list.ta-center(:header="props.data.ie_tipo_agenda == 'C' ? props.data.nm_especialista : props.data.nm_procedimento ? props.data.cd_procedimento_estabelecimento.cd_procedimento.nm_procedimento : 'AGENDA' ")
							.ta-left
								p <b>Procedimento:</b> {{ props.data.nm_procedimento }}
								p(v-if='props.data.nm_especialista ') <b>Especialista:</b> {{ props.data.nm_especialista }}
								p(v-if='props.data.cd_especialidades.length')
									b Especialidade(s):
									span.mtd-badge.co1(v-for='especialidade in props.data.cd_especialidades') {{ especialidade.nm_especialidade }}
								p <b>Tipo de agenda:</b> {{ props.data.ie_tipo_agenda_f }}
								p <b>Por vídeo:</b> {{ props.data.ie_telemedicina ? 'Sim' : 'Não' }}
								p <b>Hora marcada:</b> {{ props.data.ie_hora_marcada ? 'Sim' : 'Não' }}
							.ta-right.p-buttonset.mt-3
								Button.p-button-raised.p-button-rounded.p-button-info.mr-1(icon="jam jam-eye-f" @click='visualizar(props.data.id)')
								Button.p-button-raised.p-button-rounded.mr-1(icon="jam jam-write"
									@click="$router.push(`/agenda/salvar/${ props.data.id }/`)")
								Button.p-button-raised.p-button-rounded.p-button-danger(icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data")
			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de agendas')
				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="list" selectionMode='single' @sort='onSort' :sortField="order.field" :sortOrder="order.sortOrder" @row-click="visualizar($event.data.id)")
					Column(headerStyle='width: 4em' bodyStyle='text-align:center')
						template(#body='props')
							ProgressSpinner.waiting-status(v-if='waiting.statusIds.includes(props.data.id)' strokeWidth='6')
							.status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click.stop='alterarStatus(props.data.id)')
								i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")

					Column(headerStyle='width: 4em' bodyStyle='text-align:center')
						template(#body='props')
							ProgressSpinner.waiting-config(v-if="waitingConfig.id === props.data.id && waitingConfig.field === 'ie_agenda_restrita'" strokeWidth='6')
							.btn-config.status-indicator(v-else v-tooltip.top="`${ props.data.ie_agenda_restrita ? 'Agenda restrita' : 'Agenda visível' }`" :class="{ 'off': props.data.ie_agenda_restrita }" @click.stop="updateConfig(props.data.id, 'ie_agenda_restrita')")
								i(class='jam' :class="{ 'jam-eye-close': props.data.ie_agenda_restrita, 'jam-eye': !props.data.ie_agenda_restrita }")

					Column(headerStyle='width: 4em' bodyStyle='text-align:center' )
						template(#body='props')
							ProgressSpinner.waiting-status(v-if='waiting.statusIds.includes(props.data.id)' strokeWidth='6')
							Button(:ref="'botaoMudarAltura'+ props.data.id" :icon="botaoExpandido.includes(props.data.id) ? 'jam jam-minus' : 'jam jam-plus'" @click="toggleProcedimentos(props.data.id)" style=" width: 25px; height: 25px; font-size: 12px;")
					Column(headerStyle='width: 30%' v-if="filters.ie_tipo_agenda == 'C'" field='cd_especialista__nm_especialista' header='Especialista' :sortable="true")
						template(#body='props')
							p.aux1 {{ props.data.nm_especialista }}
							// span.mtd-badge.co1(v-for='especialidade in props.data.cd_especialidades') {{ especialidade.nm_especialidade }}
					Column(headerStyle='width: 18%' field='cd_especialidade__nm_especialidade' v-if="filters.ie_tipo_agenda == 'C'" header='Especialidade' :sortable="true")
						template(#body='props')
							p.aux1(v-for='especialidade in props.data.cd_especialidades') {{ especialidade.nm_especialidade }}
					Column(headerStyle='width: 30%;' header='Procedimento(s)')
						template(#body='props')
							.listProcedimentos(:ref="'mudarAltura'+ props.data.id" :class="{'alturaFixa': !botaoExpandido.includes(props.data.id)}")
								p(v-if='props.data.nm_procedimento') {{props.data.nm_procedimento}}
								p(v-else v-for='i in props.data.cd_procedimentos' :key='i.id')
									span(v-if='props.data.cd_procedimentos.length > 1') &bull;
									span {{ i.ds_procedimento }}

					Column(headerStyle='width: 30%' v-if="filters.ie_tipo_agenda != 'C'" field='nm_especialista' header='Médico executor')

					Column(headerStyle='width: 30%' field='ds_observacao' header='Observação')
						template(#body='props')
							p.aux1 {{ props.data.ds_observacao }}

					Column(headerStyle='width: 10%' header='Valor')
						template(#body='props')
							p.aux1 {{ calcValor(props.data.cd_procedimento_estabelecimento) }}

					//Column(headerStyle='width: 10%' bodyStyle='text-align: center' v-if="filters.ie_tipo_agenda == 'C'")
						template(#header) Por vídeo
						template(#body='props') {{ props.data.ie_telemedicina ? 'Sim': 'Não' }}

					//Column(headerStyle='width: 10%' bodyStyle='text-align: center' v-if="['E','L'].includes(filters.ie_tipo_agenda)")
						template(#header) Tipo
						template(#body='props') {{ props.data.ie_tipo_agenda == 'E' ? 'Exame': 'Laboratório' }}

					Column(headerStyle='width: 18%' header='Configurações' bodyStyle='text-align: center')
						template(#body='props')

							ProgressSpinner.waiting-config(v-if="waitingConfig.id === props.data.id && waitingConfig.field === 'ie_agenda_presencial'" strokeWidth='6')
							.btn-config.status-indicator.pink(v-else v-tooltip.top="`Presencial: ${ props.data.ie_agenda_presencial ? 'ativado' : 'desativado' }`" :class="{ 'off': !props.data.ie_agenda_presencial }" @click.stop="updateConfig(props.data.id, 'ie_agenda_presencial')")
								i(class='jam jam-map-marker')

							ProgressSpinner.waiting-config(v-if="waitingConfig.id === props.data.id && waitingConfig.field === 'ie_hora_marcada'" strokeWidth='6')
							.btn-config.status-indicator.purple(v-else v-tooltip.top="`Hora marcada: ${ props.data.ie_hora_marcada ? 'ativado' : 'desativado' }`" :class="{ 'off': !props.data.ie_hora_marcada }" @click.stop="updateConfig(props.data.id, 'ie_hora_marcada')")
								i(class='jam jam-clock')

							.btn-config.status-indicator.alt(v-tooltip.top="`Integração: ${ props.data.id_externo ? 'ativado' : 'desativado' }`" :class="{ 'off': !props.data.id_externo }")
								i(class='jam jam-link')
							
							.btn-config.status-indicator.cyan(
								v-tooltip.top="`Horários Manuais ${ props.data.total_nao_integrado }`" 
								:class="{ 'off': !props.data.total_nao_integrado > 0 }"
								style="margin-top: 4px"
							)
								i(class='jam jam-ordered-list')


					Column(headerStyle='width: 12%; text-align: center' header='Ações')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Visualizar'"
									icon="pi pi-external-link"
									@click="visualizar(props.data.id)"
								)
								Button.p-button-danger.p-button-raised.p-button-rounded(icon="jam jam-trash" @click='openDialogApagar(props.data)')
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
	.agenda-listar {
		.listProcedimentos {
			&.alturaFixa {
				height: 200px;
				overflow-y: scroll;
			}
			&.expandido {
				height: auto; /* Ou a altura desejada */
			}
		}
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.dialogApagar{
            width: 40vw;
            max-width: 350px;
        }
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
		}
		.aux1 { margin: 0; margin-bottom: 4px; margin-right: 4px; }
		.p-tabview-panels {
			padding: 0 !important;
			border: none !important;
		}
		.p-tabview {
			padding-bottom: 0;
		}

		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			transition: 250ms;
			cursor: pointer;
			background-color: #94c860;
			border: 1px solid #84b553;
			&:hover { background-color: #84b553; }
			&.alt {
				background-color: #007ad9;
				border: 1px solid #0e6bb3;
				&:hover { background-color: #0e6bb3; }
			}
			&.purple {
				background-color: #6f42c1;
				border-color: #5a32b8;
				&:hover {
					background-color: #5a32b8;
				}
			}
			&.pink {
				background-color: #d63384;
				border-color: #c61d75;
				&:hover {
					background-color: #c61d75;
				}
			}
			&.cyan {
				background-color: #17a2b8;
				border-color: #138496;
				&:hover {
					background-color: #138496;
				}
			}
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
				&.alt, &.purple, &.pink, &.cyan {
					background-color: #bbb;
					border: 1px solid #aaaaaa;
					&:hover { background-color: #aaa; }
				}
			}
			.jam {
				color: #fff;
			}
		}
		.waiting-status {
			width: 25px;
			height: auto;
		}
		.waiting-config {
			width: 20px;
			height: auto;
			margin: 0 10px;
			vertical-align: middle;
		}
		.btn-config {
			display: inline-block;
			vertical-align: middle;
			margin: 0 8px;
		}
	}
</style>

<script>
	import DataView from 'primevue/dataview'
	import Column from 'primevue/column'
	import DataTable from 'primevue/datatable'
	import Paginator from 'primevue/paginator'
	import Panel from 'primevue/panel'
	import ProgressBar from 'primevue/progressbar'
	import Dropdown from 'primevue/dropdown'
	import InputText from 'primevue/inputtext'
	import Button from 'primevue/button'
	import Dialog from 'primevue/dialog'
	import TabView from 'primevue/tabview'
	import TabPanel from 'primevue/tabpanel'
	import Tooltip from 'primevue/tooltip'
	import ProgressSpinner from 'primevue/progressspinner'

	import CustomDropdown from "./../CustomComponents/CustomDropdown.vue";

	import { Agenda, Agendamento, GrupoProcedimentos } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	export default {
		components: { DataView, Panel, ProgressBar, Dropdown, DataTable, Column, ProgressSpinner, CustomDropdown,
			InputText, Button, Dialog, TabView, TabPanel, Tooltip, Paginator },
		directives: { tooltip: Tooltip },
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if (from.path.split('/')[1] === routeName && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')

				const tabs = ['C', 'E', 'L', 'P']
				vm.filters.ie_tipo_agenda = (tabs.includes(vm.$route.query.tb)) ? vm.$route.query.tb : 'C'

				let page = (!isNaN(Number(vm.$route.query.pg))) ? vm.$route.query.pg : 1

				vm.setOrderField()
				vm.applyFilters(page)
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			let _convertDataToOptions = (key, data) => {
				this.options.procedimentos[key] = [{ text: '- Selecione -', value: null }]
				data.forEach(procedimento => {
					if (procedimento) {
						let nm_procedimento = procedimento.ds_observacao || procedimento.cd_procedimento.nm_procedimento
						this.options.procedimentos[key].push({
							value: procedimento.id,
							text: `${ procedimento.cd_procedimento.cd_procedimento } - ${ nm_procedimento }`
						})
					}
				})
			}

            this.waiting.especialidades = true
            Agendamento.getEspecialidades().then(response => {
				this.waiting.especialidades = false
				if(response.status == 200) {
					this.options.especialidades.push({ text: '- Selecione -', value: null })
					response.data.forEach(espe => {
						this.options.especialidades.push({ value: espe.id, text: espe.nm_especialidade });
					});
				}
			})

			this.waiting.grupos = true
			GrupoProcedimentos.findAll().then(response => {
				this.waiting.grupos = false
				if(response.status === 200) {
					this.options.grupos_procedimentos.push({ text: '- Selecione -', value: null })
					response.data.forEach(gp => {
						this.options.grupos_procedimentos.push({ value: gp.id, text: gp.ds_descricao });
					});
				}
			})

			const tabs = ['C', 'E', 'L', 'P']
			tabs.forEach(tab => {
				this.waiting.procedimentos = true
				Agendamento.getProcedimentos({ ie_tipo_procedimento: tab }).then(response => {
					this.waiting.procedimentos = false
					if (response.status == 200) { _convertDataToOptions(tab, response.data) }
				})
			})
		},
		data () {
			return {
				list: [],
				windowInnerWidth: window.innerWidth,
				waiting: {
					list: false,
					statusIds: [],
					grupos: false,
					especialidades: false,
					procedimentos: false,
				},
				waitingConfig: {},
				dialogApagar: false,
				dialogApagar_data: {},
				filters: {
					ie_tipo_agenda: 'C',
					cd_procedimento_estabelecimento: null,
					nm_especialista: '',
					ie_status: true,
					cd_especialidade: null,
                    cd_grupo_procedimento: null,
				},
				options: {
					procedimentos: { C: [], E: [], L: [], P: [] },
					especialidades: [],
					ie_status: [
						{ value: null, label: 'TODAS' },
						{ value: true, label: 'Habilitadas' },
						{ value: false, label: 'Desabilitadas' }
					],
					grupos_procedimentos: [],
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				order: {
					field: 'cd_especialista__nm_especialista',
					sortOrder: 1
				},
				showProcedimentos: true,
				botaoExpandido: [],
			}
		},
		methods: {
			toggleProcedimentos(id) {
				if (this.botaoExpandido.includes(id)){
					this.botaoExpandido.splice(this.botaoExpandido.indexOf(id))
				}
				else{
					this.botaoExpandido.push(id)
				}
			},
			applyFilters (page) {
				this.paginator.page = page || 1
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

				params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

				if (this.$route.query.tb != this.filters.ie_tipo_agenda || this.$route.query.pg != this.paginator.page)
					this.$router.replace( { query: { tb: this.filters.ie_tipo_agenda, pg: this.paginator.page } } )

				Object.keys(this.filters).forEach((key) => {
					if (key === 'ie_status') {
						if (this.filters[key] !== null) params[key] = this.filters[key]
					}
					else if (this.filters[key]) params[key] = this.filters[key]
				})

				this.getList(params)
			},
			getList (params) {
				this.waiting.list = true
				Agenda.findAll(params).then(response => {
					if (response.status == 200) {
						this.list = response.data.results
						this.paginator.count = response.data.count
						let tiposAgenda = {
							'C': 'Consulta',
							'E': 'Exame'
						}
						this.list.forEach(agenda => {
							agenda.ie_tipo_agenda_f = tiposAgenda[agenda.ie_tipo_agenda]

							let procedimento = agenda.cd_procedimento_estabelecimento
							if (procedimento) {
								agenda.cd_procedimento = procedimento.cd_procedimento.cd_procedimento
								agenda.nm_procedimento = procedimento.ds_observacao ? procedimento.ds_observacao : procedimento.cd_procedimento.nm_procedimento
							}
						})
					}
                    this.waiting.list = false
                })
			},
			onPage (ev) {
				this.applyFilters(ev.page + 1)
			},
			onSort (ev) {
				if (ev.sortField === 'nm_procedimento') {
					this.order.field = 'cd_procedimento_estabelecimento__cd_procedimento__nm_procedimento'
				} else this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
			},
			checkEmpty_nm_especialista () {
				if (! this.filters.nm_especialista) this.applyFilters()
			},
			visualizar (id) {
				this.$router.push({ path: `/agenda/visualizar/${ id }/` })
			},
			openDialogApagar(elem) {
				this.dialogApagar_data.nm_especialista = elem.nm_especialista
				this.dialogApagar_data.id = elem.id
				this.dialogApagar = true
			},
			onTabChange (ev) {
				this.filters.ie_tipo_agenda = ev.tab.$attrs.ieTipoAgenda
				this.filters.cd_procedimento_estabelecimento = null
				this.filters.nm_especialista = ''
				this.filters.cd_grupo_procedimento = null
				this.filters.cd_especialidade = null
				this.filters.ie_status = true
				this.setOrderField()
				this.applyFilters()
			},
			setOrderField () {
				this.order.field = (this.filters.ie_tipo_agenda === 'C')
					? 'cd_especialista__nm_especialista'
					: 'cd_procedimento_estabelecimento__cd_procedimento__nm_procedimento'
			},
			// remove (id) {
			// 	Agenda.remove(id).then(response => {
			// 		if (response.status == 200) {
			// 			let message = response.data.detail ? response.data.detail : 'Agenda removida com sucesso'
			// 			this.$toast.info(message, { duration: 4000 })
			// 			this.getList()
			// 			this.dialogApagar = false
			// 		}
			// 	})
			// },
			remove (id) {
                this.waitingRemoverAgenda = true
                Agenda.remove(id).then(({status}) => {
                    if (status == 200) {
                        this.$toast.info('Agenda removida com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.$router.go()
					}
                    this.waitingRemoverAgenda = false
                })
            },
			alterarStatus (idAgenda) { // eslint-disable-line
				let agenda = this.list.find(a => a.id === idAgenda)
				this.waiting.statusIds.push(idAgenda)
				Agenda.alterarStatusAgenda(idAgenda).then(response => {
					this.waiting.statusIds = this.waiting.statusIds.filter(id => id !== idAgenda)
					if (response.status === 200) {
						agenda.ie_status = ! agenda.ie_status
						if (agenda.ie_status)
							this.$toast.success(response.data.detail, { duration: 3000 })
						else
							this.$toast.info(response.data.detail, { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
			updateConfig (idAgenda, field) {
				if (this.waitingConfig.id) return 0
				this.waitingConfig = { id: idAgenda, field }

				let agenda = this.list.find(a => a.id === idAgenda)
				let dataSend = { id: idAgenda }
				let fieldMap = {
					'ie_agenda_presencial': 'Presencial',
					'ie_agenda_restrita': 'Agenda restrita',
					'ie_hora_marcada': 'Hora marcada'
				}

				dataSend[field] = ! agenda[field]

				Agenda.save(dataSend).then(response => {
					this.waitingConfig = {}
					if (response.status === 200) {
						agenda[field] = dataSend[field]
						if (agenda[field])
							this.$toast.success(`${ fieldMap[field] } ativado`, { duration: 3000 })
						else
							this.$toast.info(`${ fieldMap[field] } desativado`, { duration: 3000 })
					}
				})
			},
			calcValor(item){
				return item?.nr_valor_negociado && item?.nr_valor_tarifa?
					this.$root.formatPrice(+item.nr_valor_negociado + +item.nr_valor_tarifa) : ''
			}
		}
	}
</script>
